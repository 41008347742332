// src/components/steps/StepsHeader.tsx
import { ImageIcon, Check } from 'lucide-react';
import { Step } from '@/types';

interface StepsHeaderProps {
  currentStep: Step;
}

export function StepsHeader({ currentStep }: StepsHeaderProps) {
  const steps: Step[] = ['upload', 'customize', 'preview'];
  
  return (
    <div className="flex justify-between mb-8">
      <div className="flex gap-2">
        {steps.map((step, index) => (
          <div
            key={step}
            className={`flex items-center ${
              currentStep === step ? 'text-blue-600' : 'text-gray-400'
            }`}
          >
            <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
              ${currentStep === step ? 'border-blue-600 bg-blue-50' : 'border-gray-300'}`}
            >
              {step === 'upload' && <ImageIcon className="w-4 h-4" />}
              {step === 'customize' && <ImageIcon className="w-4 h-4" />}
              {step === 'preview' && <Check className="w-4 h-4" />}
            </div>
            <span className="ml-2 capitalize">{step}</span>
            {index < steps.length - 1 && (
              <div className="w-16 h-px bg-gray-300 mx-2" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
// src/utils/gifGenerator.ts

// Type definitions
interface Transform {
    x: number;
    y: number;
    scale: number;
    rotation: number;
}

interface AnimationConfig {
    frames: number;
    delay: number;
    getTransform: (progress: number) => Transform;
}

interface GIFOptions {
    workers: number;
    quality: number;
    width: number;
    height: number;
    workerScript: string;
    transparent: boolean;
    debug?: boolean;
}

interface GIFFrameOptions {
    copy: boolean;
    delay: number;
}

interface GIFInstance {
    on(event: 'finished', callback: (blob: Blob) => void): void;
    on(event: 'error', callback: (error: Error) => void): void;
    on(event: 'progress', callback: (progress: number) => void): void;
    addFrame(context: CanvasRenderingContext2D, options: GIFFrameOptions): void;
    render(): void;
}

interface WindowWithGIF extends Window {
    GIF: {
        new (options: GIFOptions): GIFInstance;
    };
}

// Animation configurations
const ANIMATION_CONFIGS: Record<string, AnimationConfig> = {
    none: {
        frames: 1,
        delay: 100,
        getTransform: () => ({
            x: 0,
            y: 0,
            scale: 1,
            rotation: 0
        })
    },
    bounce: {
        frames: 30,
        delay: 33,
        getTransform: (progress) => ({
            x: 0,
            y: -Math.sin(progress * Math.PI) * 20,
            scale: 1,
            rotation: 0
        })
    },
    shake: {
        frames: 20,
        delay: 33,
        getTransform: (progress) => ({
            x: Math.sin(progress * Math.PI * 2) * 10,
            y: 0,
            scale: 1,
            rotation: 0
        })
    },
    spin: {
        frames: 30,
        delay: 33,
        getTransform: (progress) => ({
            x: 0,
            y: 0,
            scale: 1,
            rotation: progress * Math.PI * 2
        })
    },
    wiggle: {
        frames: 20,
        delay: 33,
        getTransform: (progress) => ({
            x: 0,
            y: 0,
            scale: 1,
            rotation: Math.sin(progress * Math.PI * 2) * 0.2
        })
    },
    tada: {
        frames: 25,
        delay: 33,
        getTransform: (progress) => ({
            x: 0,
            y: 0,
            scale: 1 + Math.sin(progress * Math.PI * 2) * 0.1,
            rotation: Math.sin(progress * Math.PI * 2) * 0.15
        })
    }
};

export async function createAnimatedGif(
    imageUrl: string,
    animationType: string
): Promise<Blob> {
    console.log('🚀 Starting GIF creation...', { 
        animationType,
        imageUrl: imageUrl.substring(0, 50) + '...' 
    });

    return new Promise((resolve, reject) => {
        try {
            // Check for GIF constructor
            if (typeof window === 'undefined' || !('GIF' in window)) {
                throw new Error('GIF.js library not loaded');
            }

            const config = ANIMATION_CONFIGS[animationType] || ANIMATION_CONFIGS.none;
            const OUTPUT_SIZE = 128;

            // Create canvas
            console.log('🎨 Setting up canvas...');
            const canvas = document.createElement('canvas');
            canvas.width = OUTPUT_SIZE;
            canvas.height = OUTPUT_SIZE;
            
            const ctx = canvas.getContext('2d', { 
                alpha: true,
                willReadFrequently: true 
            });

            if (!ctx) {
                throw new Error('Failed to get canvas context');
            }

            // Enable better image rendering
            ctx.imageSmoothingEnabled = true;

            // Configure GIF options
            const gifOptions: GIFOptions = {
                workers: 1,
                quality: 10,
                width: OUTPUT_SIZE,
                height: OUTPUT_SIZE,
                workerScript: '/gif.worker.js',
                transparent: true
            };

            console.log('⚙️ Creating GIF with options:', gifOptions);

            // Create GIF instance with proper typing
            const gif = new (window as unknown as WindowWithGIF).GIF(gifOptions);
            let frameCount = 0;

            // Set up event listeners
            gif.on('finished', (blob: Blob) => {
                console.log('✅ GIF creation finished', { 
                    size: blob.size,
                    type: blob.type,
                    frames: frameCount
                });
                resolve(blob);
            });

            gif.on('error', (error: Error) => {
                console.error('❌ GIF creation error:', error);
                reject(error);
            });

            gif.on('progress', (p: number) => {
                console.log(`📈 Progress: ${Math.round(p * 100)}%`);
            });

            // Load and process image
            const img = new Image();
            img.crossOrigin = 'anonymous';

            img.onload = () => {
                try {
                    // Calculate dimensions
                    const scale = Math.min(
                        (OUTPUT_SIZE * 0.8) / img.width,
                        (OUTPUT_SIZE * 0.8) / img.height
                    );
                    const width = img.width * scale;
                    const height = img.height * scale;
                    const x = (OUTPUT_SIZE - width) / 2;
                    const y = (OUTPUT_SIZE - height) / 2;

                    console.log('📐 Dimensions calculated:', {
                        scale,
                        width,
                        height,
                        x,
                        y
                    });

                    // Generate frames
                    for (let i = 0; i < config.frames; i++) {
                        const progress = i / (config.frames - 1);
                        const transform = config.getTransform(progress);

                        // Clear canvas
                        ctx.clearRect(0, 0, OUTPUT_SIZE, OUTPUT_SIZE);

                        // Draw frame
                        ctx.save();
                        ctx.translate(OUTPUT_SIZE / 2, OUTPUT_SIZE / 2);
                        ctx.rotate(transform.rotation);
                        ctx.scale(transform.scale, transform.scale);
                        ctx.translate(-OUTPUT_SIZE / 2, -OUTPUT_SIZE / 2);
                        ctx.translate(transform.x, transform.y);

                        try {
                            ctx.drawImage(img, x, y, width, height);
                        } catch (drawError) {
                            console.error('❌ Frame drawing error:', drawError);
                            throw drawError;
                        }

                        ctx.restore();

                        // Add frame to GIF
                        try {
                            gif.addFrame(ctx, {
                                copy: true,
                                delay: config.delay
                            });
                            frameCount++;
                            console.log(`✅ Frame ${frameCount}/${config.frames} added`);
                        } catch (frameError) {
                            console.error('❌ Frame addition error:', frameError);
                            throw frameError;
                        }
                    }

                    console.log('🎬 Starting GIF render...');
                    gif.render();

                } catch (error) {
                    console.error('❌ Frame generation error:', error);
                    reject(error);
                }
            };

            img.onerror = () => {
                reject(new Error('Failed to load image'));
            };

            console.log('🔄 Loading image...');
            img.src = imageUrl;

        } catch (error) {
            console.error('❌ Fatal GIF creation error:', error);
            reject(error);
        }
    });
}
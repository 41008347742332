// src/utils/backgroundRemoval.ts
import { removeBackground } from '@imgly/background-removal';

export async function removeImageBackground(imageUrl: string): Promise<string> {
  try {
    console.log('Starting client-side background removal...');

    // Convert data URL to blob if it's a data URL
    let blob: Blob;
    if (imageUrl.startsWith('data:')) {
      const base64Data = imageUrl.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      
      blob = new Blob(byteArrays, { type: 'image/png' });
    } else {
      const response = await fetch(imageUrl);
      blob = await response.blob();
    }

    // Process image
    const resultBlob = await removeBackground(blob, {
      model: 'isnet',
      progress: (status: string, percentage: number) => {
        console.log(`Background removal status: ${status}, progress: ${Math.round(percentage * 100)}%`);
      },
    });

    // Convert result back to data URL
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(resultBlob);
    });
  } catch (error) {
    console.error('Background removal error:', error);
    throw new Error('Failed to remove background. Please try with a smaller image.');
  }
}

// src/components/customize/CustomizeSection.tsx
import { Loader2 } from 'lucide-react';
import Image from 'next/image';
import { AnimationPicker } from '../animation/AnimationPicker';

interface CustomizeSectionProps {
  processedImage: string | null;
  isProcessingBackground: boolean;
  isProcessing: boolean;
  animationControls: {
    removeBackground: boolean;
    speed: number;
  };
  selectedAnimation: string;
  onAnimationSelect: (animation: string) => void;
  onAnimationControlsChange: (controls: {
    removeBackground?: boolean;
    speed?: number;
  }) => void;
  onContinue: () => void;
}

export function CustomizeSection({
  processedImage,
  isProcessingBackground,
  isProcessing,
  animationControls,
  selectedAnimation,
  onAnimationSelect,
  onAnimationControlsChange,
  onContinue
}: CustomizeSectionProps) {
  if (!processedImage) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-center">
        <div className="relative w-32 h-32">
          <Image
            src={processedImage}
            alt="Preview"
            fill
            className="object-contain"
            unoptimized
          />
        </div>
      </div>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="removeBackground"
              checked={animationControls.removeBackground}
              onChange={(e) => {
                onAnimationControlsChange({
                  removeBackground: e.target.checked
                });
              }}
              disabled={isProcessingBackground}
              className="h-4 w-4 text-blue-600 rounded"
            />
            <label 
              htmlFor="removeBackground" 
              className="ml-2 text-sm text-gray-700 flex items-center gap-2"
            >
              Remove Background
              {isProcessingBackground && (
                <Loader2 className="w-4 h-4 animate-spin text-blue-500" />
              )}
            </label>
          </div>
        </div>
        
        <AnimationPicker
          processedImage={processedImage}
          selectedAnimation={selectedAnimation}
          onSelect={onAnimationSelect}
        />
        
        {selectedAnimation !== 'none' && (
          <div className="mt-4 space-y-4 border rounded-lg p-4">
            <div>
              <label className="text-sm font-medium text-gray-700 block mb-2">
                Animation Speed
              </label>
              <input
                type="range"
                min="0.5"
                max="2"
                step="0.1"
                value={animationControls.speed}
                onChange={(e) => onAnimationControlsChange({
                  speed: parseFloat(e.target.value)
                })}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                <span>Slower</span>
                <span>Normal</span>
                <span>Faster</span>
              </div>
            </div>
          </div>
        )}
        
        <button
          onClick={onContinue}
          disabled={isProcessing || isProcessingBackground}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isProcessing ? (
            <span className="flex items-center justify-center gap-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              Processing...
            </span>
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </div>
  );
}
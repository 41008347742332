import { useState, useCallback, useEffect, useRef } from 'react';
import { optimizeImage, validateImage, cleanupImageUrls } from '@/utils/imageOptimization';
import { Step, ImageInfo, ProcessingError } from '@/types';

interface UploadState {
  preview: string | null;
  processedImage: string | null;
  error: string | null;
  isProcessing: boolean;
  imageInfo: ImageInfo | null;
  showSuccess: boolean;
}

export function useEmojiUpload() {
  const [currentStep, setCurrentStep] = useState<Step>('upload');
  const [uploadState, setUploadState] = useState<UploadState>({
    preview: null,
    processedImage: null,
    error: null,
    isProcessing: false,
    imageInfo: null,
    showSuccess: false,
  });

  // Use ref for values that don't need to trigger re-renders
  const urlsRef = useRef<{preview: string | null; processed: string | null}>({
    preview: null,
    processed: null
  });

  // Update ref when URLs change
  useEffect(() => {
    urlsRef.current = {
      preview: uploadState.preview,
      processed: uploadState.processedImage
    };
  }, [uploadState.preview, uploadState.processedImage]);

  // Add setters for the state values
  const setPreview = useCallback((preview: string | null) => {
    setUploadState(prev => ({ ...prev, preview }));
  }, []);

  const setProcessedImage = useCallback((processedImage: string | null) => {
    setUploadState(prev => ({ ...prev, processedImage }));
  }, []);

  const setError = useCallback((error: string | null) => {
    setUploadState(prev => ({ ...prev, error }));
  }, []);

  // Cleanup function for blob URLs
  useEffect(() => {
    return () => {
      const urls = [urlsRef.current.preview, urlsRef.current.processed].filter(
        (url): url is string => typeof url === 'string'
      );
      cleanupImageUrls(urls);
    };
  }, []);

  const processImage = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    
    try {
      const response = await fetch('/api/process-image', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new ProcessingError(
          'Server failed to process image',
          'SERVER_ERROR'
        );
      }

      const data = await response.json();
      if (!data.success) {
        throw new ProcessingError(
          data.error || 'Failed to process image',
          'PROCESSING_ERROR'
        );
      }

      return {
        processedImage: data.processedImage,
        imageInfo: {
          originalSize: data.originalSize,
          processedSize: data.processedSize
        }
      };
    } catch (error) {
      if (error instanceof ProcessingError) {
        throw error;
      }
      throw new ProcessingError(
        'Network error while processing image',
        'NETWORK_ERROR'
      );
    }
  };

  const handleFile = useCallback(async (file: File) => {
    try {
      setUploadState(prev => ({
        ...prev,
        error: null,
        isProcessing: true,
        showSuccess: false
      }));

      // Validate file
      validateImage(file);

      // Optimize image client-side
      const optimized = await optimizeImage(file);
      setUploadState(prev => ({
        ...prev,
        preview: optimized.preview
      }));

      // Process on server
      const { processedImage, imageInfo } = await processImage(
        new File([optimized.blob], file.name, { type: 'image/png' })
      );

      setUploadState(prev => ({
        ...prev,
        processedImage,
        imageInfo,
        isProcessing: false,
        showSuccess: true
      }));

      setCurrentStep('customize');

      // Clear success message after delay
      const timeoutId = setTimeout(() => {
        setUploadState(prev => ({
          ...prev,
          showSuccess: false
        }));
      }, 3000);

      return () => clearTimeout(timeoutId);

    } catch (err: unknown) {
      let errorMessage = 'An unexpected error occurred';
      
      if (err instanceof ProcessingError) {
        errorMessage = err.message;
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }

      setUploadState(prev => ({
        ...prev,
        error: errorMessage,
        isProcessing: false
      }));
      
      console.error('File processing error:', err);
    }
  }, []);

  const resetUpload = useCallback(() => {
    const urls = [urlsRef.current.preview, urlsRef.current.processed].filter(
      (url): url is string => typeof url === 'string'
    );
    cleanupImageUrls(urls);

    setUploadState({
      preview: null,
      processedImage: null,
      error: null,
      isProcessing: false,
      imageInfo: null,
      showSuccess: false
    });
  }, []);

  return {
    currentStep,
    setCurrentStep,
    preview: uploadState.preview,
    processedImage: uploadState.processedImage,
    error: uploadState.error,
    isProcessing: uploadState.isProcessing,
    imageInfo: uploadState.imageInfo,
    showSuccess: uploadState.showSuccess,
    handleFile,
    resetUpload,
    setPreview,
    setProcessedImage,
    setError
  };
}
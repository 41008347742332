// src/components/animation/AnimationPicker.tsx
import { useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Image from 'next/image';

interface AnimationOption {
  id: string;
  label: string;
  className: string;
  description: string;
}

interface AnimationPickerProps {
  processedImage: string;
  selectedAnimation: string;
  onSelect: (animation: string) => void;
}

const ANIMATION_OPTIONS: AnimationOption[] = [
  {
    id: 'none',
    label: 'Static',
    className: '',
    description: 'No animation'
  },
  {
    id: 'shake',
    label: 'Shake',
    className: 'animate-shake',
    description: 'Side to side movement'
  },
  {
    id: 'bounce',
    label: 'Bounce',
    className: 'animate-bounce',
    description: 'Up and down bounce'
  },
  {
    id: 'spin',
    label: 'Spin',
    className: 'animate-spin',
    description: '360° rotation'
  },
  {
    id: 'pulse',
    label: 'Pulse',
    className: 'animate-pulse',
    description: 'Fade in and out'
  },
  {
    id: 'tada',
    label: 'Tada',
    className: 'animate-tada',
    description: 'Celebratory effect'
  }
];

export function AnimationPicker({ 
  processedImage, 
  selectedAnimation,
  onSelect 
}: AnimationPickerProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 200;
      const newScrollLeft = scrollRef.current.scrollLeft + 
        (direction === 'left' ? -scrollAmount : scrollAmount);
      
      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth'
      });

      // Update scroll buttons visibility
      setShowLeftScroll(newScrollLeft > 0);
      setShowRightScroll(
        newScrollLeft < 
        scrollRef.current.scrollWidth - scrollRef.current.clientWidth
      );
    }
  };

  return (
    <div className="relative">
      {/* Animation Options */}
      <div 
        className="relative overflow-hidden"
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          setShowLeftScroll(target.scrollLeft > 0);
          setShowRightScroll(
            target.scrollLeft < target.scrollWidth - target.clientWidth
          );
        }}
      >
        <div 
          ref={scrollRef}
          className="flex overflow-x-auto space-x-4 pb-4 scrollbar-hide"
        >
          {ANIMATION_OPTIONS.map((option) => (
            <div
              key={option.id}
              onClick={() => onSelect(option.id)}
              className={`
                flex-none w-48 p-4 border rounded-lg cursor-pointer transition-all
                ${selectedAnimation === option.id 
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20' 
                  : 'border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800'}
              `}
            >
              <div className="flex flex-col items-center gap-3">
                <div className="h-16 w-16 flex items-center justify-center relative">
                  <Image
                    src={processedImage}
                    alt={`${option.label} preview`}
                    fill
                    className={`object-contain ${option.className}`}
                    unoptimized
                  />
                </div>
                <div className="text-center">
                  <p className="font-medium">{option.label}</p>
                  <p className="text-xs text-gray-500 mt-1">
                    {option.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Scroll Buttons */}
        {showLeftScroll && (
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/90 dark:bg-black/90 p-2 rounded-full shadow-lg"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
        )}
        {showRightScroll && (
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/90 dark:bg-black/90 p-2 rounded-full shadow-lg"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        )}
      </div>

      {/* Helper Text */}
      <p className="text-xs text-gray-500 text-center mt-4">
        Scroll to see more animations. Preview how your emoji will look in Slack.
      </p>
    </div>
  );
}
export interface ImageInfo {
  originalSize: number;
  processedSize: number;
}

export interface ProcessingStatus {
  isUploading: boolean;
  isProcessing: boolean;
  isRemovingBackground: boolean;
  isCreatingGif: boolean;
  uploadProgress: number;
}

export type Step = 'upload' | 'customize' | 'preview';

export interface PreviewSize {
  label: string;
  size: number;
}

export interface AnimationOption {
  id: string;
  label: string;
  className: string;
  description: string;
}

export interface GifOptions {
  speed?: number;
  maxSize?: number;
}

export class ProcessingError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);
    this.name = 'ProcessingError';
  }
}
'use client';

import { useRef } from 'react';
import { Upload, X, Loader2 } from 'lucide-react';
import Image from 'next/image';
import { cn } from '@/lib/utils';

interface ImageInfo {
  originalSize: number;
  processedSize: number;
}

interface UploadSectionProps {
  preview: string | null;
  processedImage: string | null;
  isProcessing: boolean;
  isDragging: boolean;
  error: string | null;
  imageInfo: ImageInfo | null;
  onFileSelect: (file: File) => void;
  onDragStateChange: (isDragging: boolean) => void;
  onRemove: () => void;
}

export function UploadSection({
  preview,
  processedImage,
  isProcessing,
  isDragging,
  error,
  imageInfo,
  onFileSelect,
  onDragStateChange,
  onRemove
}: UploadSectionProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const formatSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    onDragStateChange(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    onDragStateChange(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    onDragStateChange(false);
    
    const file = e.dataTransfer.files[0];
    if (file) onFileSelect(file);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      fileInputRef.current?.click();
    }
  };

  return (
    <div
      role="presentation"
      tabIndex={0}
      aria-label={preview ? "Replace image" : "Upload image"}
      onClick={() => fileInputRef.current?.click()}
      onKeyDown={handleKeyPress}
      className={cn(
        'enhanced-upload-zone',
        `relative p-8
        ${isDragging ? 'border-blue-500 bg-blue-50/50 dark:bg-blue-900/20' : 'border-gray-300 dark:border-gray-700'}
        ${error ? 'border-red-300 bg-red-50 dark:bg-red-900/20' : ''}
        cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      aria-busy={isProcessing}
      aria-invalid={!!error}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="sr-only"
        accept="image/png,image/jpeg,image/gif"
        aria-label="Choose file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) onFileSelect(file);
        }}
      />

      <div className="flex flex-col items-center">
        {isProcessing ? (
          <div className="flex flex-col items-center" role="status" aria-live="polite">
            <Loader2 className="w-12 h-12 text-blue-500 animate-spin mb-4" aria-hidden="true" />
            <p className="text-sm text-gray-600 dark:text-gray-300">Processing image...</p>
          </div>
        ) : preview ? (
          <div className="relative">
            <div className="relative w-32 h-32">
              <Image
                src={processedImage || preview}
                alt="Image preview"
                fill
                className="object-contain"
                unoptimized
              />
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              className="absolute -top-2 -right-2 p-1 bg-gray-100 dark:bg-gray-800 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Remove image"
            >
              <X className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" />
            </button>
          </div>
        ) : (
          <>
            <Upload className="w-12 h-12 text-gray-400 mb-4" aria-hidden="true" />
            <div className="space-y-2 text-center">
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Drag and drop your image here, or click to select
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Supports PNG, JPG, and GIF up to 5MB
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                💡 Pro tip: Square images (1:1 ratio) work best for Slack emojis
              </p>
            </div>
          </>
        )}
        
        {preview && (
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
            Click or drag to replace
          </p>
        )}
        
        {!isProcessing && !error && imageInfo && (
          <div 
            className="mt-4 text-xs text-gray-500 dark:text-gray-400"
            aria-live="polite"
          >
            Original: {formatSize(imageInfo.originalSize)} → 
            Processed: {formatSize(imageInfo.processedSize)}
          </div>
        )}

        {error && (
          <div
            role="alert"
            className="mt-4 text-sm text-red-600 dark:text-red-400"
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
// src/components/EmojiUploader.tsx
'use client';

import { useState } from 'react';
import { useEmojiUpload } from '@/hooks/useEmojiUpload';
import { StepsHeader } from './steps/StepsHeader';
import { StatusMessages } from './status/StatusMessages';
import { ActionButtons } from './buttons/ActionButtons';
import { UploadSection } from './upload/UploadSection';
import { CustomizeSection } from './customize/CustomizeSection';
import { PreviewSection } from './preview/PreviewSection';
import { removeImageBackground } from '@/utils/backgroundRemoval';
import { createAnimatedGif } from '@/utils/gifGenerator';

interface AnimationControls {
  speed: number;
  removeBackground: boolean;
}

export default function EmojiUploader() {
  const {
    currentStep,
    setCurrentStep,
    preview,
    setPreview,
    processedImage,
    setProcessedImage,
    error,
    setError,
    imageInfo,
    showSuccess,
    handleFile
  } = useEmojiUpload();

  // Additional states with proper typing
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProcessingBackground, setIsProcessingBackground] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [selectedAnimation, setSelectedAnimation] = useState<string>('none');
  const [animationControls, setAnimationControls] = useState<AnimationControls>({
    speed: 1,
    removeBackground: false
  });

  const downloadImage = async (imageUrl: string, filename: string): Promise<void> => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error('Failed to fetch image');
      
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      throw new Error('Failed to download image');
    }
  };

  const handleDownload = async (): Promise<void> => {
    if (!processedImage) return;
  
    try {
        setIsProcessing(true);
        setError(null);
        
        if (selectedAnimation !== 'none') {
            try {
                console.log('Creating animated GIF...');
                const gifBlob = await createAnimatedGif(processedImage, selectedAnimation);
                
                if (!gifBlob || gifBlob.size === 0) {
                    throw new Error('Generated GIF is invalid');
                }
                
                const downloadUrl = URL.createObjectURL(gifBlob);
                const downloadLink = document.createElement('a');
                downloadLink.href = downloadUrl;
                downloadLink.download = `slack-emoji-${selectedAnimation}.gif`;
                
                // Append, click, and cleanup
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadUrl);
                
                console.log('GIF download complete');
            } catch (error) {
                console.error('Animation creation error:', error);
                setError('Failed to create animated GIF. Please try again with a smaller image or different animation.');
                return;
            }
        } else {
            await downloadImage(processedImage, 'slack-emoji.png');
        }
        
        setCurrentStep('preview');
    } catch (error) {
        console.error('Download error:', error);
        setError(error instanceof Error ? error.message : 'Failed to download emoji');
    } finally {
        setIsProcessing(false);
    }
};



  const handleCopy = async (): Promise<void> => {
    if (!processedImage) return;
    
    try {
      const response = await fetch(processedImage);
      if (!response.ok) throw new Error('Failed to fetch image');
      
      const blob = await response.blob();
      await navigator.clipboard.write([
        new ClipboardItem({ [blob.type]: blob })
      ]);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      console.error('Copy error:', error);
      setError('Failed to copy image');
    }
  };

  const handleAnimationControlsChange = async (changes: Partial<AnimationControls>): Promise<void> => {
    const newControls = { ...animationControls, ...changes };
    
    if (changes.removeBackground && processedImage) {
      setIsProcessingBackground(true);
      setError(null);
      
      try {
        const removedBgUrl = await removeImageBackground(processedImage);
        setProcessedImage(removedBgUrl);
        setAnimationControls(newControls);
      } catch (error) {
        console.error('Background removal failed:', error);
        setError(error instanceof Error ? error.message : 'Failed to remove background');
        setAnimationControls(prev => ({
          ...prev,
          removeBackground: false
        }));
      } finally {
        setIsProcessingBackground(false);
      }
    } else {
      setAnimationControls(newControls);
    }
  };

  const handleCreateAnother = (): void => {
    setCurrentStep('upload');
    setProcessedImage(null);
    setPreview(null);
    setSelectedAnimation('none');
    setAnimationControls({ speed: 1, removeBackground: false });
  };

  return (
    <div className="max-w-2xl mx-auto p-4 min-h-[calc(100vh-200px)]">
      <StepsHeader currentStep={currentStep} />

      <div className="space-y-6">
        {currentStep === 'upload' && (
          <div className="transition-opacity duration-300">
            <UploadSection
              preview={preview}
              processedImage={processedImage}
              isProcessing={isProcessing}
              isDragging={isDragging}
              error={error}
              imageInfo={imageInfo}
              onFileSelect={handleFile}
              onDragStateChange={setIsDragging}
              onRemove={() => {
                setPreview(null);
                setProcessedImage(null);
                setError(null);
              }}
            />
          </div>
        )}

        {currentStep === 'customize' && (
          <div className="transition-opacity duration-300">
            <CustomizeSection
              processedImage={processedImage}
              isProcessingBackground={isProcessingBackground}
              isProcessing={isProcessing}
              animationControls={animationControls}
              selectedAnimation={selectedAnimation}
              onAnimationSelect={setSelectedAnimation}
              onAnimationControlsChange={handleAnimationControlsChange}
              onContinue={handleDownload}
            />
          </div>
        )}

        {currentStep === 'preview' && (
          <div className="transition-opacity duration-300">
            <div className="space-y-6">
              <PreviewSection
                processedImage={processedImage}
                selectedAnimation={selectedAnimation}
                animationSpeed={animationControls.speed}
              />
              
              <ActionButtons
                onDownload={handleDownload}
                onCopy={handleCopy}
                onCreateAnother={handleCreateAnother}
                copySuccess={copySuccess}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        )}

        <StatusMessages error={error} showSuccess={showSuccess} />

        <div className="mt-4 text-center text-xs text-gray-500 dark:text-gray-400">
          Pro tip: You can also paste images directly from your clipboard (Ctrl/Cmd + V)
        </div>
      </div>
    </div>
  );
}
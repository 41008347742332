// src/components/preview/PreviewSection.tsx
'use client';

import { useState } from 'react';
import { Sun, Moon } from 'lucide-react';
import Image from 'next/image';

const PREVIEW_SIZES = [
  { label: 'Small', size: 16 },
  { label: 'Medium', size: 24 },
  { label: 'Large', size: 32 },
  { label: 'Extra Large', size: 48 }
];

interface PreviewSectionProps {
  processedImage: string | null;
  selectedAnimation: string;
  animationSpeed?: number;
}

export function PreviewSection({
  processedImage,
  selectedAnimation,
  animationSpeed = 1
}: PreviewSectionProps) {
  const [activeTab, setActiveTab] = useState<'sizes' | 'themes' | 'message'>('sizes');

  if (!processedImage) return null;

  const animationStyle = {
    animationDuration: selectedAnimation !== 'none' ? `${1 / animationSpeed}s` : undefined
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      {/* Tabs */}
      <div className="border-b dark:border-gray-700">
        <div className="flex">
          <button
            onClick={() => setActiveTab('sizes')}
            className={`px-6 py-2 text-sm transition-colors relative
              ${activeTab === 'sizes' 
                ? 'text-blue-500' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200'}
            `}
          >
            Sizes
            {activeTab === 'sizes' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500" />
            )}
          </button>
          <button
            onClick={() => setActiveTab('themes')}
            className={`px-6 py-2 text-sm transition-colors relative
              ${activeTab === 'themes'
                ? 'text-blue-500' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200'}
            `}
          >
            Themes
            {activeTab === 'themes' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500" />
            )}
          </button>
          <button
            onClick={() => setActiveTab('message')}
            className={`px-6 py-2 text-sm transition-colors relative
              ${activeTab === 'message'
                ? 'text-blue-500' 
                : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200'}
            `}
          >
            Message
            {activeTab === 'message' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500" />
            )}
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="p-4">
        {activeTab === 'sizes' && (
          <div>
            <h3 className="text-sm text-gray-700 dark:text-gray-300 mb-4">Size Variations</h3>
            <div className="flex items-center justify-center gap-8">
              {PREVIEW_SIZES.map(({ label, size }) => (
                <div key={size} className="flex flex-col items-center gap-2">
                  <div className="relative" style={{ width: size, height: size }}>
                    <Image
                      src={processedImage}
                      alt={`${label} preview`}
                      fill
                      className={`object-contain ${selectedAnimation !== 'none' ? selectedAnimation : ''}`}
                      style={animationStyle}
                      unoptimized
                    />
                  </div>
                  <span className="text-xs text-gray-500 dark:text-gray-400">{label}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'themes' && (
          <div className="space-y-4">
            <h3 className="text-sm text-gray-700 dark:text-gray-300 mb-4">Theme Preview</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="p-6 bg-white border rounded-lg shadow-sm hover:shadow-md transition-all duration-200 flex items-center justify-center">
                <div className="flex items-center gap-2">
                  <Sun className="w-4 h-4 text-gray-400" />
                  <div className="relative w-12 h-12">
                    <Image
                      src={processedImage}
                      alt="Light theme preview"
                      fill
                      className={`object-contain ${selectedAnimation !== 'none' ? selectedAnimation : ''}`}
                      style={animationStyle}
                      unoptimized
                    />
                  </div>
                </div>
              </div>
              <div className="p-6 bg-gray-900 border rounded-lg shadow-sm hover:shadow-md transition-all duration-200 flex items-center justify-center">
                <div className="flex items-center gap-2">
                  <Moon className="w-4 h-4 text-gray-400" />
                  <div className="relative w-12 h-12">
                    <Image
                      src={processedImage}
                      alt="Dark theme preview"
                      fill
                      className={`object-contain ${selectedAnimation !== 'none' ? selectedAnimation : ''}`}
                      style={animationStyle}
                      unoptimized
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'message' && (
          <div className="p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
            <div className="flex items-start gap-3">
              <div className="w-8 h-8 rounded-lg bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                <span className="text-sm font-medium text-gray-600 dark:text-gray-300">U</span>
              </div>
              <div className="flex-1">
                <div className="flex items-baseline gap-2">
                  <span className="font-medium text-gray-900 dark:text-gray-100">User</span>
                  <span className="text-xs text-gray-400">12:34 PM</span>
                </div>
                <p className="text-gray-600 dark:text-gray-300 flex items-center gap-2 mt-1">
                  Check out this new emoji!
                  <div className="relative w-5 h-5 inline-block">
                    <Image
                      src={processedImage}
                      alt="Emoji in message"
                      fill
                      className={`object-contain inline-block ${selectedAnimation !== 'none' ? selectedAnimation : ''}`}
                      style={animationStyle}
                      unoptimized
                    />
                  </div>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
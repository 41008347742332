import { ProcessingError } from '@/types';

interface ImageDimensions {
  width: number;
  height: number;
}

interface OptimizedImage {
  blob: Blob;
  dimensions: ImageDimensions;
  preview: string;
}

export const optimizeImage = async (
  file: File,
  maxDimension = 512,
  quality = 0.9
): Promise<OptimizedImage> => {
  try {
    // Create image object
    const img = await createImageFromFile(file);
    
    // Calculate dimensions
    const dimensions = calculateDimensions(img, maxDimension);
    
    // Create canvas and context
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      throw new ProcessingError(
        'Failed to get canvas context',
        'CANVAS_CONTEXT_ERROR'
      );
    }

    // Set canvas dimensions
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    // Draw image with smooth scaling
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

    // Convert to blob
    const blob = await new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new ProcessingError(
              'Failed to create image blob',
              'BLOB_CREATION_ERROR'
            ));
          }
        },
        'image/png',
        quality
      );
    });

    // Create preview URL
    const preview = URL.createObjectURL(blob);

    return {
      blob,
      dimensions,
      preview,
    };
  } catch (error) {
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw new ProcessingError(
      'Failed to optimize image',
      'OPTIMIZATION_ERROR'
    );
  }
};

const createImageFromFile = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new ProcessingError(
      'Failed to load image',
      'IMAGE_LOAD_ERROR'
    ));
    img.src = URL.createObjectURL(file);
  });
};

const calculateDimensions = (
  img: HTMLImageElement,
  maxDimension: number
): ImageDimensions => {
  const ratio = img.width / img.height;
  let width = img.width;
  let height = img.height;

  if (width > maxDimension || height > maxDimension) {
    if (width > height) {
      width = maxDimension;
      height = Math.round(maxDimension / ratio);
    } else {
      height = maxDimension;
      width = Math.round(maxDimension * ratio);
    }
  }

  return { width, height };
};

export const validateImage = (file: File): void => {
  const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 5 * 1024 * 1024; // 5MB

  if (!validTypes.includes(file.type)) {
    throw new ProcessingError(
      'Invalid file type. Please upload a PNG, JPG, or GIF file.',
      'INVALID_FILE_TYPE'
    );
  }

  if (file.size > maxSize) {
    throw new ProcessingError(
      'File is too large. Maximum size is 5MB.',
      'FILE_TOO_LARGE'
    );
  }
};

export const cleanupImageUrls = (urls: string[]) => {
  urls.forEach(url => {
    if (url.startsWith('blob:')) {
      URL.revokeObjectURL(url);
    }
  });
};
import { Check } from 'lucide-react';

interface StatusMessagesProps {
  error: string | null;
  showSuccess: boolean;
}

export function StatusMessages({ error, showSuccess }: StatusMessagesProps) {
  if (!error && !showSuccess) return null;

  return (
    <>
      {error && (
        <div className="mt-4 p-4 bg-red-50 rounded-md border border-red-200">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}

      {showSuccess && !error && (
        <div className="mt-4 p-4 bg-green-50 rounded-md border border-green-200 flex items-center gap-2">
          <Check className="w-4 h-4 text-green-500" />
          <p className="text-sm text-green-600">Image processed successfully!</p>
        </div>
      )}
    </>
  );
}
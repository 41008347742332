// src/components/buttons/ActionButtons.tsx
import { ImageIcon, Copy, Check, Loader2 } from 'lucide-react';

interface ActionButtonsProps {
  onDownload: () => void;
  onCopy: () => void;
  onCreateAnother: () => void;
  copySuccess: boolean;
  isProcessing: boolean;
}

export function ActionButtons({ 
  onDownload, 
  onCopy, 
  onCreateAnother, 
  copySuccess,
  isProcessing
}: ActionButtonsProps) {
  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <button
          onClick={onDownload}
          disabled={isProcessing}
          className="flex-1 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isProcessing ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <ImageIcon className="w-4 h-4" />
          )}
          <span>{isProcessing ? 'Processing...' : 'Download'}</span>
        </button>
        <button
          onClick={onCopy}
          disabled={isProcessing}
          className="px-4 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          title="Copy to clipboard"
        >
          {copySuccess ? (
            <Check className="w-4 h-4 text-green-500" />
          ) : (
            <Copy className="w-4 h-4" />
          )}
        </button>
      </div>

      <button
        onClick={onCreateAnother}
        disabled={isProcessing}
        className="w-full border border-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-50 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Create Another
      </button>
    </div>
  );
}